import React from "react"
import styles from "./testimonials.module.scss"

import Testimonial from "../components/testimonial"

const Testimonials = ({ items }) => (
  <div className={styles.Testimonials}>
    {items.map((item, index) => (
      <div className={styles.item} key={index}>
        <Testimonial
          reverse={index % 2}
          content={() => (
            <span dangerouslySetInnerHTML={{ __html: item.node.html }} />
          )}
          cite={item.node.frontmatter}
        />
      </div>
    ))}
  </div>
)

export default Testimonials
