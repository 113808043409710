import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styles from "./screenshots.module.scss"

const LinkWrapper = ({ url, title, children }) => {
  if (url) {
    return (
      <Link to={url} title={title}>
        {children}
      </Link>
    )
  }

  return children
}

const Screenshots = ({ url, title, sources, loading }) => {
  // These styles fix a problem in `gatsby-image` where using
  // art direction with images that have different aspect ratios
  // defaults to using the aspect ratio of the first image passed
  // to `fluidSources`.
  // https://github.com/gatsbyjs/gatsby/pull/13395#issuecomment-506402173
  let aspectRatioFix = ``
  sources.forEach(({ media, aspectRatio }) => {
    aspectRatioFix += `
      @media only screen and ${media} {
        [class^="screenshots-module"] .gatsby-image-wrapper > div:first-child {
            padding-bottom: ${100 / aspectRatio}% !important;
        }
      }
    `
  })

  return (
    <div className={styles.Screenshots}>
      <style
        dangerouslySetInnerHTML={{
          __html: aspectRatioFix,
        }}
      />
      <LinkWrapper url={url} title={title}>
        <Img
          fadeIn={false}
          fluid={sources}
          alt={`${title} screenshot`}
          loading={loading}
        />
      </LinkWrapper>
    </div>
  )
}

Screenshots.defaultProps = {
  loading: "lazy",
}

export default Screenshots
